import * as React from "react"
import { ExternalLink } from "../components/external-link"
import { FollowImage } from "../components/images/follow-image"
import Layout from "../components/layout"
import { submissionFormLink } from "../constants"
import style from "./list-your-business.module.scss"

const ideaTypes = [
  {
    name: "Shops",
    iconFileName: "icon-shops.png",
    ideas: [
      "Demos",
      "Fashion show with stuff to sell",
      "Interview artists/designers",
    ],
  },
  {
    name: "Misc",
    iconFileName: "icon-misc.png",
    ideas: ["Free toilet paper per purchase", "Free sanitizer per purchase"],
  },
  {
    name: "Restaurants",
    iconFileName: "icon-restaurant.png",
    ideas: [
      "Deals of the day",
      "Virtual cooking classes",
      "Knife skill class",
      "Interviews with founders",
      "Cocktail making kits",
      "Sell ingredients (pasta/sausage)",
    ],
  },
  {
    name: "Exercise",
    iconFileName: "icon-restaurant.png",
    ideas: [
      "Online class (YouTube, Instagram Live)",
      "Demo's of equipment",
      "Nutrition class",
      "AMA's on health topic",
    ],
  },
]

const IdeaSection = ({ name, image, ideas }) => (
  <section className={style.ideaSection}>
    <FollowImage filename={image} />
    <h2 className={style.ideaSectionTitle}>{name}</h2>
    <ul className={style.ideaSectionList}>
      {ideas.map(idea => (
        <li className={style.ideaSectionListItem}>{idea}</li>
      ))}
    </ul>
  </section>
)

const IdeasSection = () => (
  <div className={style.ideasList}>
    <h1 className={style.ideasListTitle}>Creative ideas for your customers</h1>
    {ideaTypes.map(ideaType => (
      <IdeaSection
        name={ideaType.name}
        ideas={ideaType.ideas}
        image={ideaType.iconFileName}
      />
    ))}
    <p className={style.ideasNote}>
      If you have any ideas that businesses can offer you, please email us @{" "}
      <a href="mailto:admin@thepaoapp.com">admin@thepaoapp.com</a> and we will
      add them to our list!
    </p>
  </div>
)

const ListYourBusinessPage = () => (
  <Layout backgroundImage="bg-shop.png">
    <section className={style.pageHeader}>
      <h1 className={style.pageHeaderText}>Join Us!</h1>
      <p className={style.pageHeaderDetailText}>
        If you are open for business and offering your customers something to do
        today, you can list here in less than 5 minutes! Post your business
        either for your own city AND/OR under virtual/anywhere if customers can
        take advantage of your business in an online fashion. If you want to
        provide your own photo for your listing, please email it to{" "}
        <a href="mailto:admin@thepaoapp.com?subject=New%20Spot%20Submission">
          admin@thepaoapp.com
        </a>{" "}
        along with your business name and location. Otherwise we will find one
        for you. We are adding new cities as soon as we get 5-10 offerings.
      </p>
      <div className={style.pageHeaderButtonContainer}>
        <ExternalLink
          className={style.pageHeaderButton}
          href={submissionFormLink}
        >
          List Your Business
        </ExternalLink>
      </div>
    </section>
    <IdeasSection />
  </Layout>
)

export default ListYourBusinessPage
